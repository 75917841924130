import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				 Ocean's Embrace Charters
			</title>
			<meta name={"description"} content={"Ocean's Embrace Charters спеціалізується на створенні розкішних і незабутніх чартерів яхт. "} />
			<meta property={"og:title"} content={" Ocean's Embrace Charters"} />
			<meta property={"og:description"} content={"Ocean's Embrace Charters спеціалізується на створенні розкішних і незабутніх чартерів яхт. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});